import { h } from 'hyperapp';
import Submit from '~/common/account/components/submit.jsx';
const Button = () => h("button", {
  class: "mdui-btn mdui-ripple more-option",
  type: "button",
  "mdui-menu": "{target: '#mc-login-menu', position: 'top', covered: true}"
}, "\u66F4\u591A\u9009\u9879", h("i", {
  class: "mdui-icon material-icons mdui-text-color-theme-icon"
}, "arrow_drop_down"));
const Menu = ({
  actions
}) => h("ul", {
  class: "mdui-menu",
  id: "mc-login-menu"
}, h("li", {
  class: "mdui-menu-item"
}, h("a", {
  onclick: actions.toReset,
  class: "mdui-ripple"
}, "\u5FD8\u8BB0\u5BC6\u7801")), h("li", {
  class: "mdui-menu-item"
}, h("a", {
  onclick: actions.toRegister,
  class: "mdui-ripple"
}, "\u521B\u5EFA\u65B0\u8D26\u53F7")));
export default (({
  state,
  actions
}) => h("div", {
  class: "actions mdui-clearfix"
}, h(Button, null), h(Menu, {
  actions: actions
}), h(Submit, {
  disabled: state.submitting,
  text: state.submitting ? '登录中…' : '登录'
})));