import { h } from 'hyperapp';
import { Link } from 'hyperapp-router';
import $ from 'mdui.jq';
import { unescape } from 'html-escaper';
import { fullPath } from '~/utils/path';
import { emit } from '~/utils/pubsub';
import currentUser from '~/utils/currentUser';
import './index.less';
import Follow from '~/components/follow/view.jsx';
import OptionsButton from '~/components/options-button/view.jsx';
const StatItem = ({
  url,
  label,
  count
}) => h(Link, {
  to: url,
  class: "mdui-btn mdui-ripple"
}, h("label", {
  class: "mdui-text-color-theme-secondary"
}, label), h("span", {
  class: "mdui-text-color-theme-text"
}, count));
export default (({
  actions,
  user,
  dataName,
  primaryKey = null,
  primaryValue = null
}, children) => h("div", {
  class: "mc-user-popover",
  key: "mc-user-popover",
  onclick: event => event.preventDefault(),
  oncreate: element => {
    actions.onUserPopoverCreate({
      element,
      dataName,
      primaryKey,
      primaryValue
    });
  }
}, children, h("div", {
  class: "popover mdui-menu",
  key: "popover"
}, user.cover ? [h("div", {
  class: "mc-user-line",
  key: "0"
}, h(Link, {
  key: "avatar",
  class: "avatar user-popover-trigger",
  to: fullPath(`/users/${user.user_id}`),
  style: {
    backgroundImage: `url("${user.avatar.middle}")`
  }
}), h(Link, {
  key: "username",
  class: "username user-popover-trigger mdui-text-color-theme-text",
  to: fullPath(`/users/${user.user_id}`)
}, user.username), h("div", {
  key: "headline",
  class: "headline mdui-text-color-theme-secondary"
}, unescape(user.headline))), h("div", {
  class: "stats",
  key: "1"
}, h(StatItem, {
  url: fullPath(`/users/${user.user_id}#answers`),
  label: "\u56DE\u7B54",
  count: user.answer_count
}), h(StatItem, {
  url: fullPath(`/users/${user.user_id}#articles`),
  label: "\u6587\u7AE0",
  count: user.article_count
}), h("button", {
  class: "mdui-btn mdui-ripple",
  onclick: () => {
    emit('users_dialog_open', {
      type: 'followers',
      id: user.user_id
    });
  }
}, h("label", {
  class: "mdui-text-color-theme-secondary"
}, "\u5173\u6CE8\u8005"), h("span", {
  class: "mdui-text-color-theme-text"
}, user.follower_count))), h("div", {
  class: "bottom",
  key: "2"
}, !currentUser() || currentUser().user_id !== user.user_id ? h(Follow, {
  item: user,
  type: "relationships-user",
  dataName: dataName,
  primaryKey: primaryKey,
  id: primaryValue,
  actions: actions
}) : null, h("div", {
  class: "flex-grow"
}), h(OptionsButton, {
  type: "user",
  item: user
}))] : null, !user.cover ? h("div", {
  key: "mc-user-popover-spinner",
  class: "mdui-spinner",
  oncreate: element => $(element).mutation()
}) : null)));