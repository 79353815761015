import extend from 'mdui.jq/es/functions/extend';
import commonActions from '~/utils/actionsAbstract';
import { emit } from '~/utils/pubsub';
import { getCookie, setCookie } from '~/utils/cookie';
import $ from 'mdui.jq';
import { startLive2d, showMessage } from '~/components/live2d/message';
const as = {
  onCreate: () => (state, actions) => {
    emit('route_update');
    actions.setTitle('设置');
    if (getCookie('live2d') == '') {
      setCookie('live2d', 'false', 5 * 360 * 24 * 3600);
    }
    ;
    $('.settings-switch').each(function () {
      $(this).prop('checked', function () {
        if (getCookie(this.getAttribute('cName')) == 'true') {
          return true;
        } else {
          return false;
        }
      });
    });
    $('.settings-switch').on('click', function () {
      setCookie(this.getAttribute('cName'), String(this.checked), 5 * 360 * 24 * 3600);
      if (this.getAttribute('cName') === 'live2d') {
        if (this.checked) {
          startLive2d();
          showMessage('你好啊~我目前只有在打开设置时才会出现！', 3000);
        } else {
          showMessage('我走了~下次有缘再见！', 1000);
          setTimeout(startLive2d, 1000);
        }
        ;
      }
      ;
    });
  }
};
export default extend(as, commonActions);