import { h } from 'hyperapp';
import cc from 'classcat';
import $ from 'mdui.jq';
import { COMMON_IMAGE_UPLOAD_FAILED } from 'mdclub-sdk-js/es/errors';
import Editor from 'mdui.editor/es/index';
import './index.less';
import TopicChip from './components/topic-chip/view.jsx';
const HeaderIcon = ({
  iconTitle,
  icon,
  onClick
}) => h("i", {
  class: "mdui-icon material-icons",
  title: iconTitle,
  onclick: onClick
}, icon);
export default (({
  id,
  // 唯一ID
  title,
  // 头部显示的名称
  withTitle = false,
  // 是否包含标题
  withTopics = false,
  // 是否包含话题选择
  onSubmit,
  // 点提交按钮的函数
  publishing,
  // 是否正在提交内容
  state,
  // 包含 stateAbstract 中的 editorState 和 topicSelectorState
  actions // 包含 actionsAbstract 中的 editorActions 和 topicSelectorActions
}) => {
  const Header = () => h("div", {
    class: "header"
  }, h("div", {
    class: "header-title",
    onclick: actions.editorMinimize
  }, title), h("div", {
    class: "header-actions"
  }, h(HeaderIcon, {
    iconTitle: "\u6700\u5C0F\u5316",
    icon: "remove",
    onClick: actions.editorMinimize
  }), h(HeaderIcon, {
    iconTitle: "\u5168\u5C4F\u6A21\u5F0F",
    icon: state.editor_maximize ? 'fullscreen_exit' : 'fullscreen',
    onClick: actions.editorMaximize
  }), h(HeaderIcon, {
    iconTitle: "\u4FDD\u5B58\u4E3A\u8349\u7A3F\u5E76\u5173\u95ED",
    icon: "close",
    onClick: actions.editorClose
  })));
  const Title = () => h("input", {
    class: "editor-title mdui-text-color-theme-text",
    placeholder: "\u8BF7\u8F93\u5165\u6807\u9898",
    value: window.localStorage.getItem(`${state.auto_save_key}-title`),
    oninput: event => {
      window.localStorage.setItem(`${state.auto_save_key}-title`, event.target.value);
    }
  });
  const Topics = () => h("div", {
    class: "editor-topics"
  }, state.editor_selected_topics.length ? h("div", {
    class: "chip-wrapper"
  }, state.editor_selected_topics.map((topic, selectedIndex) => h(TopicChip, {
    topic: topic,
    onRemove: () => {
      actions.topicSelectorRemoveOne(selectedIndex);
    }
  }))) : null, !state.editor_selected_topics.length ? h("span", {
    class: "placeholder",
    title: "\u70B9\u51FB\u6DFB\u52A0\u8BDD\u9898",
    onclick: actions.topicSelectorOpen
  }, "\u8BF7\u81F3\u5C11\u9009\u62E9 1 \u4E2A\u8BDD\u9898") : null, h("button", {
    class: "add mdui-btn mdui-btn-icon mdui-btn-dense mdui-ripple",
    title: "\u70B9\u51FB\u6DFB\u52A0\u8BDD\u9898",
    onclick: actions.topicSelectorOpen
  }, h("i", {
    class: "mdui-icon material-icons mdui-text-color-theme-icon"
  }, "add")));
  const SubmitButton = () => h("button", {
    class: "submit mdui-btn mdui-btn-raised mdui-color-theme",
    disabled: publishing,
    onclick: event => {
      const $dialog = $(event.target).parents('.mc-editor');
      const editor = $dialog.data('editor-instance');
      onSubmit({
        title: $dialog.find('.editor-title').val(),
        content: editor.getHTML()
      });
    }
  }, publishing ? '发布中' : '发布');
  return h("div", {
    class: cc(['mc-editor', {
      'mdui-hidden': !state.editor_open,
      maximize: state.editor_maximize,
      minimize: state.editor_minimize,
      'with-title': withTitle,
      'with-topics': withTopics
    }]),
    id: id,
    key: `mc-editor-${id}`,
    oncreate: element => {
      const $element = $(element);
      const $toolbar = $element.find('.editor-toolbar');
      const $content = $element.find('.editor-content');
      const editor = new Editor($toolbar, $content, {
        onClearDrafts: () => {
          if (withTitle) {
            window.localStorage.removeItem(`${state.auto_save_key}-title`);
          }
          if (withTopics) {
            window.localStorage.removeItem(`${state.auto_save_key}-topics`);
          }
          actions.editorClose();
        },
        placeholder: '请输入内容',
        autoSave: !!state.auto_save_key,
        autoSaveKey: `${state.auto_save_key}-content`,
        imageUploadMaxSize: 0,
        imageUploadUrl: `${window.G_API}/images`,
        imageUploadName: 'image',
        imageUploadResponseTransform: response => {
          if (response.code === COMMON_IMAGE_UPLOAD_FAILED) {
            response.message = response.extra_message;
          }
          if (response.code === 0) {
            response.data.url = response.data.urls.release;
          }
          return response;
        }
      });

      // 保存编辑器实例到元素中
      $element.data('editor-instance', editor);
    }
  }, h(Header, null), h("div", {
    class: "body"
  }, withTitle ? h(Title, null) : null, withTopics ? h(Topics, null) : null, h("div", {
    class: "editor-content mdui-text-color-theme-text"
  }), h("div", {
    class: "editor-toolbar"
  }, h(SubmitButton, null))));
});