import { h } from 'hyperapp';
import cc from 'classcat';
import './index.less';
export default (({
  state,
  actions
}) => {
  const Item = ({
    reason
  }) => h("label", {
    class: "mdui-list-item"
  }, h("div", {
    class: "mdui-radio"
  }, h("input", {
    type: "radio",
    name: "reason",
    value: reason,
    checked: reason === state.reason,
    onchange: event => actions.onChange(event)
  }), h("i", {
    class: "mdui-radio-icon"
  })), h("div", {
    class: "mdui-list-item-content"
  }, reason));
  return h("div", {
    oncreate: element => actions.onCreate({
      element
    }),
    key: "report-dialog",
    class: "mc-report-dialog mdui-dialog"
  }, h("div", {
    class: "mdui-dialog-title"
  }, h("span", null, "\u4E3E\u62A5"), h("button", {
    class: "mdui-btn mdui-btn-icon mdui-ripple",
    onclick: actions.close
  }, h("i", {
    class: "mdui-icon material-icons mdui-text-color-theme-icon"
  }, "close"))), h("form", {
    class: "mdui-dialog-content",
    method: "post"
  }, h("div", {
    class: "mdui-list"
  }, h(Item, {
    reason: "\u5783\u573E\u5E7F\u544A\u4FE1\u606F"
  }), h(Item, {
    reason: "\u4E0D\u53CB\u5584\u884C\u4E3A"
  }), h(Item, {
    reason: "\u6709\u5BB3\u4FE1\u606F"
  }), h(Item, {
    reason: "\u6D89\u5ACC\u4FB5\u6743"
  }), h(Item, {
    reason: "\u8BF1\u5BFC\u8D5E\u540C\u3001\u5173\u6CE8\u7B49\u884C\u4E3A"
  }), h(Item, {
    reason: "\u5176\u4ED6\u539F\u56E0"
  }), h("div", {
    class: cc(['mdui-textfield', {
      'mdui-hidden': state.reason !== '其他原因'
    }])
  }, h("textarea", {
    class: "custom-reason mdui-textfield-input",
    placeholder: "\u8BF7\u8F93\u5165\u539F\u56E0"
  })))), h("div", {
    class: "mdui-dialog-actions"
  }, h("button", {
    class: "mdui-btn mdui-ripple",
    "mdui-dialog-cancel": true
  }, "\u53D6\u6D88"), h("button", {
    class: "mdui-btn mdui-ripple",
    "mdui-dialog-confirm": true
  }, "\u4E3E\u62A5")));
});