import { h } from 'hyperapp';
import Submit from '~/common/account/components/submit.jsx';
const Button = ({
  actions
}) => h("div", {
  class: "mdui-btn mdui-ripple more-option",
  onclick: actions.toLogin
}, "\u5DF2\u6709\u8D26\u53F7\uFF1F");
export default (({
  state,
  actions
}) => h("div", {
  class: "actions"
}, h(Button, {
  actions: actions
}), h(Submit, {
  disabled: state.verifying,
  text: state.verifying ? '正在验证…' : '下一步'
})));