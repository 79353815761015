import { h } from 'hyperapp';
import './index.less';
export default ((state, actions) => ({
  match
}) => h("div", {
  oncreate: actions.onCreate,
  ondestroy: actions.onDestroy,
  key: match.url,
  id: "page-settings",
  class: "mdui-container"
}, h("div", {
  class: "mdui-divider"
}), h("ul", {
  class: "mdui-list"
}, h("li", {
  class: "mdui-list-item mdui-ripple"
}, h("i", {
  class: "mdui-list-item-icon mdui-icon material-icons"
}, "face"), h("div", {
  class: "mdui-list-item-content"
}, "Live2D\u770B\u677F\u5A18"), h("label", {
  class: "mdui-switch"
}, h("input", {
  type: "checkbox",
  class: "settings-switch",
  cName: "live2d"
}), h("i", {
  class: "mdui-switch-icon"
}))))));