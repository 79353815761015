import { h } from 'hyperapp';
import $ from 'mdui.jq';
import './index.less';
export default (() => h("div", {
  id: "live2d-landlord"
}, h("div", {
  class: "live2d-message",
  style: "opacity:0"
}), h("canvas", {
  id: "live2d",
  width: "280",
  height: "250",
  class: "live2d"
}), h("div", {
  class: "live2d-hide-button"
}, "\u9690\u85CF")));

//import './autoload.js' ;
import './live2d.js';
import './message.js';