import { h } from 'hyperapp';
import cc from 'classcat';
import { Link } from 'hyperapp-router';
import { isUndefined } from 'mdui.jq/es/utils';
import { richText, summaryText } from '~/utils/html';
import { emit } from '~/utils/pubsub';
import { fullPath } from '~/utils/path';
import './index.less';
import Loading from '~/components/loading/view.jsx';
import Loaded from '~/components/loaded/view.jsx';
import Empty from '~/components/empty/view.jsx';
import UserLine from '~/components/user-line/view.jsx';
import Follow from '~/components/follow/view.jsx';
import Editor from '~/components/editor/view.jsx';
import OptionsButton from '~/components/options-button/view.jsx';
import TopicsBar from '~/components/topics-bar/view.jsx';
import ListHeader from '~/components/list-header/view.jsx';
import Nav from '~/components/nav/view.jsx';
import AnswerItem from './components/answer-item/view.jsx';
import CommentButton from './components/comment-button/view.jsx';
export default ((state, actions) => ({
  match
}) => {
  // 判断当前是提问详情页还是回答详情页
  let answer_id = 0;
  const route = isUndefined(match.params.answer_id) ? 'question' : 'answer';
  if (route === 'answer') {
    answer_id = parseInt(match.params.answer_id, 10);
  }
  const question_id = parseInt(match.params.question_id, 10);
  const {
    question,
    loading,
    answer_data,
    answer_pagination,
    answer_loading
  } = state;
  let answer_count = 0;
  if (question) {
    answer_count = question.answer_count;
  }
  if (answer_pagination) {
    answer_count = answer_pagination.total;
  }
  const isEmpty = !answer_loading && answer_pagination && !answer_pagination.total;
  const AllAnswersLink = ({
    count,
    url
  }) => h(Link, {
    class: "all-answers mdui-text-color-theme-text",
    to: fullPath(url)
  }, "\u67E5\u770B\u5168\u90E8 ", count, " \u4E2A\u56DE\u7B54");
  return h("div", {
    oncreate: () => actions.onCreate({
      question_id,
      answer_id,
      route
    }),
    ondestroy: actions.onDestroy,
    key: match.url,
    id: "page-question",
    class: "mdui-container"
  }, h(Nav, {
    path: answer_id ? `/questions/${question_id}` : '/questions'
  }), h("div", {
    class: "mdui-card mdui-card-shadow question"
  }, question ? [h("h1", {
    class: "title",
    oncreate: summaryText(question.title),
    onupdate: summaryText(question.title),
    key: "0"
  }), h(UserLine, {
    actions: actions,
    user: question.relationships.user,
    time: question.create_time,
    dataName: "question",
    key: "1"
  }), h("div", {
    class: "mdui-typo content",
    oncreate: richText(question.content_rendered),
    onupdate: richText(question.content_rendered),
    key: "2"
  }), question.relationships.topics.length ? h(TopicsBar, {
    topics: question.relationships.topics,
    key: "3"
  }) : null, h("div", {
    class: "actions",
    key: "4"
  }, h(Follow, {
    item: question,
    type: "question",
    actions: actions
  }), h(CommentButton, {
    item: question,
    onClick: () => {
      emit('comments_dialog_open', {
        commentable_type: 'question',
        commentable_id: question_id
      });
    }
  }), h("div", {
    class: "flex-grow"
  }), h(OptionsButton, {
    type: "question",
    item: question,
    extraOptions: [{
      name: `查看 ${question.follower_count} 位关注者`,
      onClick: () => {
        emit('users_dialog_open', {
          type: 'question_followers',
          id: question_id
        });
      }
    }]
  }))] : null, h(Loading, {
    show: loading
  })), route === 'question' ? [h(Empty, {
    show: isEmpty,
    title: "\u5C1A\u672A\u6709\u4EBA\u56DE\u7B54\u8BE5\u95EE\u9898",
    key: "0"
  }), h(ListHeader, {
    show: !isEmpty,
    title: `共 ${answer_count} 个回答`,
    disabled: answer_loading,
    currentOrder: state.answer_order,
    orders: [{
      order: '-vote_count',
      name: '最热门'
    }, {
      order: 'create_time',
      name: '发布时间（从早到晚）'
    }, {
      order: '-create_time',
      name: '发布时间（从晚到早）'
    }],
    onChangeOrder: actions.changeOrder,
    key: "1"
  }), answer_data && answer_data.length ? h("div", {
    class: "mdui-card answers",
    key: "2"
  }, answer_data.map(answer => h(AnswerItem, {
    answer: answer,
    actions: actions
  }))) : null, h(Loaded, {
    show: !answer_loading && answer_pagination && answer_pagination.page === answer_pagination.pages,
    key: "3"
  })] : null, route === 'answer' && question ? [h(AllAnswersLink, {
    count: question.answer_count,
    url: `/questions/${question_id}`,
    key: "0"
  }), h("div", {
    class: "mdui-card answers",
    key: "1"
  }, h(AnswerItem, {
    answer: answer_data[0],
    actions: actions
  })), h(AllAnswersLink, {
    count: question.answer_count,
    url: `/questions/${question_id}`,
    key: "2"
  })] : null, h(Loading, {
    show: answer_loading
  }), h("button", {
    class: cc(['mdui-fab', 'mdui-fab-fixed', 'mdui-fab-extended', 'mdui-ripple', 'mdui-color-theme', {
      'mdui-hidden': state.editor_open
    }]),
    onclick: actions.editorOpen
  }, h("i", {
    class: "mdui-icon material-icons"
  }, "add"), h("span", null, "\u5199\u56DE\u7B54")), h(Editor, {
    id: "page-question-editor",
    title: "\u5199\u56DE\u7B54",
    withTitle: false,
    withTopics: false,
    onSubmit: actions.publishAnswer,
    publishing: state.answer_publishing,
    state: state,
    actions: actions
  }));
});