import { h } from 'hyperapp';
import './index.less';
export default ((state, actions) => ({
  match
}) => h("div", {
  oncreate: actions.onCreate,
  ondestroy: actions.onDestroy,
  key: match.url,
  id: "page-help",
  class: "mdui-container"
}, h("article", {
  className: "markdown-body"
}, h("h1", null, "\u4F7F\u7528\u5E2E\u52A9"), h("p", null, "Hi! \u6B22\u8FCE\u6765\u5230", h("a", {
  href: "https://hyclub.top"
}, "\u5F18\u6BC5Club"), "\uFF0C\u4EE5\u4E0B\u662F\u4E00\u4E9B\u57FA\u672C\u64CD\u4F5C\u548C\u4ECB\u7ECD\u3002"), h("h3", null, "\u521B\u5EFA\u7528\u6237"), h("ol", null, h("li", null, "\u70B9\u51FB\u7F51\u7AD9\u53F3\u4E0A\u89D2\u7684", h("code", null, "\u6CE8\u518C"), "\u6807\u7B7E\uFF0C\u8F93\u5165\u4E00\u4E2A", h("a", {
  href: "#email_jump"
}, "\u53EF\u7528\u7684\u7535\u5B50\u90AE\u4EF6\u5730\u5740")), h("li", null, "\u70B9\u51FB", h("code", null, "\u53D1\u9001\u9A8C\u8BC1\u7801"), "\uFF0C\u524D\u5F80", h("strong", null, "\u4F60\u7684\u90AE\u7BB1"), "\u67E5\u770B\u9A8C\u8BC1\u7801\u5E76\u586B\u5165\u3002"), h("li", null, h("p", null, "\u8BBE\u7F6E\u7528\u6237\u540D\u548C\u5BC6\u7801\uFF1A", h("mark", null, "\u786E\u4FDD\u4F60\u7684\u7528\u6237\u540D\u5177\u6709\u7279\u8272\u3001\u6613\u4E8E\u5206\u8FA8\uFF0C\u5BC6\u7801\u6613\u4E8E\u8BB0\u5FC6\u800C\u53C8\u4E0D\u5931\u5F3A\u5EA6\uFF01")), h("p", null, "\u53CD\u4F8B\uFF1A", h("img", {
  src: "https://hyclub.top/upload/image/2023-11/04/da/daf0551a4f64fee74f3f23ffd61cbb26_release.png",
  alt: "\u53CD\u4F8B",
  title: "\u53CD\u4F8B"
}))), h("li", null, "\u767B\u5F55\u8D26\u53F7\u65F6\u82E5\u51FA\u73B0\u56FE\u5F62\u9A8C\u8BC1\u7801\uFF0C\u8BF7\u8BB0\u4F4F\uFF1A\u56FE\u5F62\u9A8C\u8BC1\u7801\u75315\u4F4D\u6570\u5B57\u6216\u5B57\u6BCD\u7EC4\u6210\uFF0C\u4E0D\u5206\u5927\u5C0F\u5199\u3002")), h("h3", null, "\u7F16\u8F91\u4FE1\u606F"), h("blockquote", null, h("p", null, "\u8BBA\u575B\u4E2D\u63D0\u4F9B\u4E86\u4E30\u5BCC\u7684\u4E2A\u4EBA\u8D44\u6599\u7684\u7F16\u8F91\u529F\u80FD")), h("h4", null, "\u66F4\u6539\u5934\u50CF\u4E0E\u5C01\u9762"), h("p", null, "\u5934\u50CF\uFF1A\u5728", h("strong", null, "\u4E2A\u4EBA\u8D44\u6599"), "\u680F\u4E2D\u70B9\u51FB\u5934\u50CF\uFF0C\u9009\u62E9\u56FE\u7247\u4E0A\u4F20"), h("p", null, "\u5C01\u9762\uFF1A\u5C06\u4E2A\u4EBA\u8D44\u6599\u9875\u62C9\u81F3\u6700\u4E0A\u65B9\uFF0C\u70B9\u51FB", " ", h("i", {
  className: "mdui-icon material-icons"
}, "photo_camera"), " \uFF0C\u9009\u62E9\u56FE\u7247\u4E0A\u4F20\u3002"), h("br", null), h("p", null, "Tip:\u82E5\u51FA\u73B0\u56FE\u7247\u65E0\u6CD5\u4E0A\u4F20\u7684\u60C5\u51B5\uFF0C\u8BF7\u5148\u5C1D\u8BD5\u5728", h("a", {
  href: "https://www.iloveimg.com/zh-cn/compress-image",
  target: "_blank"
}, "ILoveIMG"), "\u4E2D\u5BF9\u56FE\u7247\u8FDB\u884C\u538B\u7F29\uFF01"), h("h4", null, "\u7F16\u8F91\u8BE6\u7EC6\u8D44\u6599"), h("p", null, "\u5728\u4E2A\u4EBA\u8D44\u6599\u680F\u70B9\u51FB\u5934\u50CF\u4E0B\u7684", h("i", {
  className: "mdui-icon material-icons mdui-text-color-theme-icon"
}, "edit"), "\u56FE\u6807\uFF0C\u8FDB\u884C\u7F16\u8F91\u3002"), h("p", null, h("mark", null, "[BUG]"), ":"), h("p", null, "\u5728\u7F16\u8F91\u8D44\u6599\u65F6\uFF0C\u9694\u4E00\u6BB5\u65F6\u95F4\u6240\u8F93\u5165\u7684\u6587\u5B57\u5C31\u4F1A\u88AB\u91CD\u7F6E\u3002\u8BF7\u52FF\u60CA\u614C\uFF0C\u5728\u6CA1\u4FEE\u590D\u4E4B\u524D\uFF0C\u4F60\u53EF\u4EE5\u8BD5\u8BD5\u62FC\u624B\u901F\u3002"), h("h3", null, "\u53D1\u5E03\u6587\u7AE0\u4E0E\u63D0\u95EE"), h("h4", null, "\u533A\u522B"), h("p", null, "\u6587\u7AE0\u8868\u8FBE\u4E3B\u89C2\u601D\u60F3\uFF1B\u63D0\u95EE\u6B63\u5982\u5176\u540D\uFF0C\u5177\u6709\u95EE\u7B54\u7684\u529F\u80FD\u3002"), h("h4", null, "\u53D1\u5E03\u6D41\u7A0B"), h("ol", null, h("li", null, h("p", null, "\u786E\u7ACB\u4E3B\u9898"), h("p", null, "\u60F3\u597D\u8981\u53D1\u5E03\u7684\u5185\u5BB9\uFF0C\u8FDB\u884C\u5168\u65B9\u9762\u521B\u4F5C\u3002\u6CA1\u8BB0\u4F4F\u4E5F\u6CA1\u5173\u7CFB\uFF0C\u7F16\u8F91\u5668\u63D0\u4F9B\u4E86\u4E34\u65F6\u50A8\u5B58\u8349\u7A3F\u7684\u529F\u80FD\u3002")), h("li", null, h("p", null, "\u9009\u62E9\u8BDD\u9898")), h("li", null, h("p", null, "\u9AD8\u7EA7\u521B\u4F5C"), h("p", null, "\u8BF4\u5B9E\u8BDD\u76EE\u524D\u7684\u81EA\u7814\u7F16\u8F91\u5668\u786E\u5B9E\u96BE\u7528\uFF0C\u4E4B\u540E\u4F1A\u8003\u8651\u66F4\u6362\u4E3A", h("a", {
  href: "https://markdown.com.cn/",
  target: "_blank"
}, "Markdown"), "\u8BED\u6CD5\u7684\u7F16\u8F91\u5668\u3002"), h("p", null, "\u5982\u679C\u60A8\u61C2\u5F97Markdown\uFF0C\u60A8\u53EF\u4EE5\u5C06md\u6587\u4EF6\u53D1\u9001\u81F3\u7AD9\u957F\uFF0C\u7531\u7AD9\u957F\u8FDB\u884C\u6E32\u67D3\u53D1\u5E03\uFF01"))), h("h3", null, "BUG"), h("p", null, "\u7531\u4E8E\u8BBA\u575B\u76EE\u524D\u5E76\u4E0D\u6210\u719F\uFF0C\u6709\u9519\u8BEF\u662F\u5728\u6240\u96BE\u514D\u7684\u3002\u6211\u4EEC\u6B22\u8FCE\u5927\u5BB6\u7684\u6279\u8BC4\u4E0E\u7EA0\u6B63\uFF0C\u82E5\u5728\u4F7F\u7528\u65F6\u9047\u5230\u4E86BUG\uFF0C\u8BF7\u53D1\u5E03\u6587\u7AE0\u81F3", h("code", null, "BUGs"), "\u8BDD\u9898\u4E0B\uFF0C\u5C3D\u91CF\u505A\u5230\u63CF\u8FF0\u5B8C\u6574\u6E05\u6670\uFF0C\u65B9\u4FBF\u6211\u4EEC\u5FEB\u901F\u5730\u89E3\u51B3\u3002"), h("hr", null), h("p", null, h("a", {
  id: "email_jump"
}, "\u5E38\u89C1\u7535\u5B50\u90AE\u4EF6\u5730\u5740")), h("p", null, "\u7406\u8BBA\u4E0A\u6765\u8BB2\u4EFB\u4F55\u7535\u5B50\u90AE\u7BB1\u90FD\u53EF\u4F7F\u7528\uFF0C\u53EA\u8981\u4FDD\u8BC1\u80FD\u6B63\u5E38\u6536\u4FE1\u606F\u3002"), h("p", null, "\u5BF9\u4E8E \u6CA1\u6709/\u4E0D\u77E5\u9053 \u7535\u5B50\u90AE\u7BB1\u7684\u7528\u6237\uFF0C\u53EF\u4EE5\u4F7F\u7528", h("a", {
  href: "https://mail.qq.com",
  title: "QQ\u90AE\u7BB1",
  target: "_blank"
}, "QQ\u90AE\u7BB1"), "\u5177\u4F53\u683C\u5F0F\u5982", h("code", null, "\u4F60\u7684QQ\u53F7@qq.com"), h("p", null, "\u901A\u5E38\uFF0C\u53EA\u8981\u4F60\u6709QQ\u90A3\u9ED8\u8BA4\u5C31\u62E5\u6709\u4E00\u4E2AQQ\u90AE\u7BB1\u3002 ")), h("h4", null, "\u5176\u4ED6\u5E38\u89C1\u7684\u90AE\u7BB1\u670D\u52A1\uFF1A"), h("ul", null, h("li", null, h("a", {
  href: "https://mail.163.com",
  title: "\u7F51\u6613163\u90AE\u7BB1",
  target: "_blank"
}, "\u7F51\u6613163\u90AE\u7BB1")), h("li", null, h("a", {
  href: "https://outlook.live.com",
  title: "Outlook",
  target: "_blank"
}, "Outlook")), h("li", null, h("a", {
  href: "https://www.126.com/",
  title: "\u7F51\u6613126\u90AE\u7BB1",
  target: "_blank"
}, "\u7F51\u6613126\u90AE\u7BB1"))))));