import { h } from 'hyperapp';
import mdui from 'mdui';
import $ from 'mdui.jq';
import { unescape } from 'html-escaper';
import './index.less';
const Title = () => h("div", {
  class: "mdui-dialog-title"
}, h("button", {
  class: "mdui-btn mdui-btn-icon mdui-ripple close",
  "mdui-dialog-close": true
}, h("i", {
  class: "mdui-icon material-icons"
}, "close")), "\u7F16\u8F91\u4E2A\u4EBA\u4FE1\u606F");
const Textfield = ({
  label,
  maxlength,
  name,
  value,
  type
}) => h("div", {
  class: "mdui-textfield mdui-textfield-floating-label",
  onupdate: element => mdui.updateTextFields(element)
}, h("label", {
  class: "mdui-textfield-label"
}, label), type === 'text' ? h("input", {
  class: "mdui-textfield-input",
  name: name,
  type: "text",
  maxlength: maxlength,
  value: unescape(value)
}) : null, type === 'textfield' ? h("textarea", {
  class: "mdui-textfield-input",
  name: name,
  maxlength: maxlength,
  value: unescape(value)
}) : null);
const Content = ({
  user
}) => h("form", {
  class: "mdui-dialog-content"
}, h(Textfield, {
  label: "\u4E00\u53E5\u8BDD\u4ECB\u7ECD",
  maxlength: 40,
  name: "headline",
  value: user.headline,
  type: "text"
}), h(Textfield, {
  label: "\u4E2A\u4EBA\u4E3B\u9875",
  maxlength: 80,
  name: "blog",
  value: user.blog,
  type: "text"
}), h(Textfield, {
  label: "\u6240\u5C5E\u5B66\u6821\u6216\u4F01\u4E1A",
  maxlength: 80,
  name: "company",
  value: user.company,
  type: "text"
}), h(Textfield, {
  label: "\u6240\u5728\u5730\u533A",
  maxlength: 80,
  name: "location",
  value: user.location,
  type: "text"
}), h(Textfield, {
  label: "\u4E2A\u4EBA\u7B80\u4ECB",
  maxlength: 160,
  name: "bio",
  value: user.bio,
  type: "textfield"
}));
const Actions = ({
  edit_info_submitting
}) => h("div", {
  class: "mdui-dialog-actions"
}, h("button", {
  class: "mdui-btn",
  "mdui-dialog-close": true
}, "\u53D6\u6D88"), h("button", {
  class: "mdui-btn",
  "mdui-dialog-confirm": true,
  disabled: edit_info_submitting
}, edit_info_submitting ? '保存中' : '确定'));
export default (({
  user,
  edit_info_submitting
}) => h("div", {
  class: "mdui-dialog edit-info",
  oncreate: element => $(element).mutation()
}, h(Title, null), h(Content, {
  user: user
}), h(Actions, {
  edit_info_submitting: edit_info_submitting
})));